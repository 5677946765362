import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { ItemAvailability, OrgUnitAvailability, OrgUnitLocation, ProductWarehouseInventoryInformation, SearchArea, StoreHours } from '@msdyn365-commerce/retail-proxy';
import { getOrgUnitLocationsByAreaAsync, getStoreHoursAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/OrgUnitsDataActions.g';
import { getEstimatedAvailabilityAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { IFullOrgUnitAvailability } from './utilities/full-org-unit-availability';
import { IProductInventoryInformation } from './utilities/product-inventory-information';
import { mapProductInventoryInformation } from './utilities/product-inventory-utils';

/**
 * Get selected variant action input class
 */
export class GetFullAvailableInventoryNearbyInput implements IActionInput {
   public latitude?: number;
   public longitude?: number;
   public radius?: number;
   public productId?: number;
   public DistanceUnitValue?: number;
   public IgnoreLocation?: boolean;

   constructor(_productId?: number, _latitude?: number, _longitude?: number, _radius?: number, _DistanceUnitValue?: number, _IgnoreLocation?: boolean) {
       this.productId = _productId;
       this.latitude = _latitude;
       this.longitude = _longitude;
       this.radius = _radius;
       this.DistanceUnitValue = _DistanceUnitValue;
       this.IgnoreLocation = _IgnoreLocation;
   }

   public getCacheKey = () => `GetFullAvailableInventoryNearbyInputCache`;
   public getCacheObjectType = () => 'GetFullAvailableInventoryNearbyInput';
   public dataCacheType = (): CacheType => 'none';
}

/**
 * CreateInput method for the getSelectedVariant data action
 * @param inputData The input data passed to the createInput method
 */
export const createGetFullAvailableInventoryNearbyInput = (inputData: ICreateActionContext<IGeneric<IAny>>): GetFullAvailableInventoryNearbyInput => {
    return new GetFullAvailableInventoryNearbyInput();
};

/**
 * Action method for the getSelectedVariant data aciton
 * @param input The action input class
 * @param ctx The action context
 */
export async function getFullAvailableInventoryNearbyAction(
    input: GetFullAvailableInventoryNearbyInput,
    ctx: IActionContext
): Promise<IFullOrgUnitAvailability[] | undefined> {
    // No valid product we want to return undefined so module knows there are no results yet
    if (!input.productId) {
        return undefined;
    }

    if (((!input.radius && input.radius !== 0) || !input.latitude || !input.longitude) && !input.IgnoreLocation) {
        // No valid location we want to return empty array so module can show no locations message
        return [];
    }

    const searchArea: SearchArea = {
        Latitude: input.latitude,
        Longitude: input.longitude,
        Radius: input.radius,
        DistanceUnitValue: input.DistanceUnitValue || 0 // 0 is miles
    };

    return getEstimatedAvailabilityAsync({callerContext: ctx}, {ProductIds: [input.productId], SearchArea: searchArea, DefaultWarehouseOnly:false, FilterByChannelFulfillmentGroup: true})
    .then(async (productWarehouseInformation: ProductWarehouseInventoryInformation) => {
        const productInventoryInformation = mapProductInventoryInformation(ctx, productWarehouseInformation?.ProductWarehouseInventoryAvailabilities);
        return getOrgUnitLocationsByAreaAsync({callerContext: ctx}, searchArea)
        .then(async (stores: OrgUnitLocation[]) => {
            // Constructing a store mapping based on the InventoryId.
            const storeMap = new Map<string, OrgUnitLocation>();
            stores.forEach(store => {
                if(store.InventoryLocationId) {
                    storeMap.set(store.InventoryLocationId, store);
                }
            });
            const availabilityPromiseList = stores.map(store => _getAvailabilityWithHours(store, productInventoryInformation, storeMap,ctx));
            return Promise.all(availabilityPromiseList);
        })
        .catch((error: Error) => {
            ctx.trace('[GetFullAvailableInventoryNearby] error getting Available Inventory Nearby');
            ctx.trace(error.message);
            ctx.telemetry.error(error.message);
            ctx.telemetry.debug(`[GetFullAvailableInventoryNearby] error getting Available Inventory Nearby`);
            return [];
        });
    })
    .catch((error: Error) => {
        ctx.trace('[GetFullAvailableInventoryNearby][getEstimatedAvailabilityAsync] error getting availability product warehouse information.');
        ctx.trace(error.message);
        ctx.telemetry.error(error.message);
        ctx.telemetry.debug(`[GetFullAvailableInventoryNearby][getEstimatedAvailabilityAsync] error getting availability product warehouse information.`);
        return [];
    });
}

/**
 * Action method that obtains the store information along with store hours and product availability
 * @param orgUnitLocation The org unit location
 * @param productWarehouseInventoryInformation The product ware house information
 * @param storeMap a map that contains store information group by the inventory location id
 * @param ctx The action context
 */
async function _getAvailabilityWithHours(
    orgUnitLocation: OrgUnitLocation,
    productInventoryInformation: IProductInventoryInformation[],
    storeMap: Map<string, OrgUnitLocation>,
    ctx: IActionContext): Promise<IFullOrgUnitAvailability> {
        if (!orgUnitLocation || !orgUnitLocation.OrgUnitNumber) {
            return { OrgUnitAvailability: undefined };
        }

        return getStoreHoursAsync({ callerContext: ctx}, orgUnitLocation.OrgUnitNumber)
            .then((hours: StoreHours) => {
                const itemAvailabilities: ItemAvailability[] = [];
                if(productInventoryInformation
                && storeMap) {
                        productInventoryInformation.forEach(element => {
                            if(element.InventLocationId && storeMap.has(element.InventLocationId) && element.InventLocationId === orgUnitLocation.InventoryLocationId) {
                                itemAvailabilities.push({AvailableQuantity:element.ProductAvailableQuantity?.AvailableQuantity});
                            }
                    });
                }

                const availability: OrgUnitAvailability = {
                    OrgUnitLocation: orgUnitLocation,
                    ItemAvailabilities: itemAvailabilities
                };

                if (hours && !(hours instanceof Error)) {
                    return { OrgUnitAvailability: availability, StoreHours: hours, ProductInventoryInformation: productInventoryInformation };
                }

                return { OrgUnitAvailability: availability, ProductInventoryInformation: productInventoryInformation };
            })
            .catch((error: Error) => {
                ctx.trace('[GetFullAvailableInventoryNearby] error getting availability with hours');
                ctx.trace(error.message);
                ctx.telemetry.exception(error);
                ctx.telemetry.debug(`[GetFullAvailableInventoryNearby] error getting availability with hours`);
                return { OrgUnitAvailability: {} };
            });
}

/**
 * The complete getFullAvailableInventoryNearby data action
 */
export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-full-available-inventory-nearby',
    action: <IAction<IFullOrgUnitAvailability[] | undefined>>getFullAvailableInventoryNearbyAction,
    input: createGetFullAvailableInventoryNearbyInput
});