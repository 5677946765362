import { ObjectExtensions } from './objectExtensions';

/**
 * Namespace which extends enum functionality.
 */
export namespace EnumExtensions {
    // tslint:disable:no-any

    /*
     * @name getValues
     * @description Returns a list of numeric values and enum names for the specified enum.
     * @export
     * @public
     * @param {any} value The enum to examine.
     * @returns {(number | string)[]} An array of numeric values and enum names.
     */
    export function getValues(enumClass: any): (number | string)[] {
        if (ObjectExtensions.isNullOrUndefined(enumClass)) {
            return [];
        }

        return Object.keys(enumClass).map(item => enumClass[item]);
    }

    /*
     * @name getNames
     * @description Returns a list of enum names for the specified enum.
     * @export
     * @public
     * @param {any} value The enum to examine.
     * @returns {(number | string)[]} An array of string values.
     */
    export function getNames(enumClass: any): string[] {
        return getValues(enumClass).filter(ObjectExtensions.isString);
    }

    /*
     * @name getNumericValues
     * @description Returns a list of numeric values for the specified enum.
     * @export
     * @public
     * @param {any} value The enum to examine.
     * @returns {number[]} An array of numeric values.
     */
    export function getNumericValues(enumClass: any): number[] {
        return getValues(enumClass).filter(ObjectExtensions.isNumber);
    }

    /*
     * @name getEnumValues
     * @description Returns a list of enum values for the specified enum.
     * @export
     * @public
     * @param {any} value The enum to examine.
     * @returns {number[]} An array of enum values.
     */
    export function getEnumValues<T extends number>(enumClass: any): T[] {
        return getNumericValues(enumClass).map(value => <T>value);
    }

    // tslint:enable:no-any
}