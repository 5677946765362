import { IActionContext } from '@msdyn365-commerce/core';
import MsDyn365 from '@msdyn365-commerce/core';
import { action, computed, observable } from 'mobx';
import { IListMapViewState, ILoadMapApiInput, IStoreInfo, IStoreSelectionStateContext, IStoreSelectorLocation, IStoreSelectorStateManager } from './i-store-selection-state-manager';

/**
 * Abstract instance of IStoreSelectorStateManager with base implementation
 * of everything except the geoLocate function
 */
export abstract class BaseStoreSelectorStateManager implements IStoreSelectorStateManager {

    @computed public get isDialogOpen(): Readonly<boolean> {
        return this._context !== undefined;
    }
    @computed public get context(): Readonly<IStoreSelectionStateContext | undefined> {
        return this._context;
    }

    @observable public isMapApiLoaded?: boolean;
    @observable public selectedStoreLocationId?: string;
    @observable public preferredStore?: IStoreInfo;
    @observable public listMapViewState: IListMapViewState = { displayList: true, displayMap: false };
    @observable public isMapModuleLoaded?: boolean;
    @observable protected _context?: IStoreSelectionStateContext;
    private preferredStoreLocalStorageKey: string = '_msdyn365__preferredStore_';

    constructor() {
        this.isMapModuleLoaded = false;
        const value = this._readFromToCookie(this.preferredStoreLocalStorageKey);
        try {
            const storeInfo: IStoreInfo | undefined = value ? JSON.parse(value) : undefined;
            if(storeInfo) {
                this.preferredStore = storeInfo;
            }
        } catch {
            this.preferredStore = undefined;
        }

    }

    public abstract geoLocate(searchTerm: string, actionContext: IActionContext): Promise<IStoreSelectorLocation | undefined>;

    public abstract loadMapApi(input: ILoadMapApiInput): Promise<void>;

    @action
    public async toggleListMapViewState(): Promise<void> {
        this.listMapViewState = {
            displayList: !this.listMapViewState.displayList,
            displayMap: !this.listMapViewState.displayMap
        };
    }

    @action
    public async setMapModuleLoaded(input: boolean): Promise<void> {
        this.isMapModuleLoaded = input;
    }

    @action
    public async setOrgUnitStoreInformation(input: IStoreSelectionStateContext): Promise<void> {
        this._context = input;
    }

    @action
    public async setSelectedStoreLocationId(storeId: string | undefined): Promise<void> {
        this.selectedStoreLocationId = storeId;
    }

    @action
    public async setPreferredStoreLocation(storeId?: string, storeName?: string | undefined, writeToCookie: boolean = false, latitude?: number, longitude?: number): Promise<void> {
        const storeInfo: IStoreInfo = { StoreId: storeId, StoreName: storeName, Latitude: latitude, Longitude: longitude };
        this.preferredStore = storeInfo;
        if (writeToCookie) {
            this._writeToCookie(this.preferredStoreLocalStorageKey, JSON.stringify(storeInfo));
        }
    }

    @action
    public async clearPreferredStore(writeToCookie: boolean): Promise<void> {
        this.preferredStore = undefined;
        if (writeToCookie) {
            this._removeCookie(this.preferredStoreLocalStorageKey);
        }
    }

    @action
    public async openDialog(input: IStoreSelectionStateContext): Promise<void> {
        if (this._context && this._context.id === input.id) {
            throw new Error('Dialog is already opened');
        }

        this._context = input;
    }

    @action
    public async closeDialog(): Promise<void> {
        this._context = undefined;
    }

    private _removeCookie(key: string): void {
        if (!key) {
            return;
        }
        if (MsDyn365.isBrowser) {
            const storage = window.localStorage;
            storage.removeItem(key);
        }
    }

    private _writeToCookie(key: string, value: string | undefined): void {
        if (!key || !value) {
            return;
        }
        if (MsDyn365.isBrowser) {
            const storage = window.localStorage;
            storage.setItem(key, value);
        }
    }

    private _readFromToCookie(key: string): string | undefined {
        if (!key) {
            return undefined;
        }
        if (MsDyn365.isBrowser) {
            const storage = window.localStorage;
            const cookieValue = storage.getItem(key);
            if (cookieValue) {
                return cookieValue;
            }
        }

        return undefined;
    }
}