import { ObjectExtensions } from './objectExtensions';

/**
 * Namespace which extends Array functionality.
 */
export namespace ArrayExtensions {
    /*
     * @name unique
     * @description Remove duplicate elements in the array.
     * @export
     * @public
     * @param {array} value The array to examine.
     * @returns {array} An array with only unique elements.
     */
    export function unique<T>(value: T[]): T[] {
        if (!hasElements(value)) {
            return [];
        }

        return Array.from(new Set(value));
    }

    /*
     * @name hasElements
     * @description Verifies whether the object array has elements.
     * @export
     * @public
     * @param {array} value The array to examine.
     * @returns {boolean} True if the object has elements, false otherwise.
     */
    export function hasElements<T>(value: ReadonlyArray<T>): boolean {
        return !ObjectExtensions.isNullOrUndefined(value) && value.length > 0;
    }
}