/**
 * Namespace which extends Object functionality.
 */
export namespace ObjectExtensions {
    // tslint:disable:no-any

    /*
     * @name isNullOrUndefined
     * @description Verifies whether the value is null or undefined.
     * @export
     * @public
     * @param {any} value The value to examine.
     * @returns {boolean} True if the value is null or undefined, false otherwise.
     */
    export function isNullOrUndefined(value: any): value is null | undefined  {
        return value === null || value === undefined;
    }

    /*
     * @name isString
     * @description Verifies whether the value is of type string.
     * @export
     * @public
     * @param {any} value The value to examine.
     * @returns {boolean} True if the value is a string, false otherwise.
     */
    export function isString(value: any): value is string {
        return typeof value === 'string';
    }

    /*
     * @name isNumber
     * @description Verifies whether the value is of type number.
     * @export
     * @public
     * @param {any} value The value to examine.
     * @returns {boolean} True if the value is a number, false otherwise.
     */
    export function isNumber(value: any): value is number {
        return typeof value === 'number';
    }

    // tslint:enable:no-any
}