import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    IAny,
    ICommerceApiSettings,
    ICreateActionContext,
    IGeneric
} from '@msdyn365-commerce/core';
import { getCartState } from '@msdyn365-commerce/global-state';
import {  CartLine, ProductWarehouse } from '@msdyn365-commerce/retail-proxy';
import { getOrgUnitConfigurationAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/OrgUnitsDataActions.g';
import {
    getEstimatedAvailabilityAsync,
    getEstimatedProductWarehouseAvailabilityAsync
} from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import {
    ActiveCartProductsInput,
    ArrayExtensions,
    buildCacheKey,
    getActiveCartProductsAction,
    IProductInventoryInformation,
    mapProductInventoryInformation
} from './index';
/**
 * Input class for availabilities for items in cart
 */
export class ProductAvailabilitiesForCartLineItems implements IActionInput {
    private apiSettings: ICommerceApiSettings;

    constructor(apiSettings: ICommerceApiSettings) {
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey(`ActiveCartLineItemsAvailability`, this.apiSettings);
    public getCacheObjectType = () => 'ActiveCartLineItemsAvailability';
    public dataCacheType = (): CacheType => 'none';
}

const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>) => {
    return new ProductAvailabilitiesForCartLineItems(inputData.requestContext.apiSettings);
};

/**
 * Calls the Retail API to get the product availabilities for items in the cart
 */
// tslint:disable-next-line:cyclomatic-complexity tslint:disable: max-func-body-length
export async function getAvailabilitiesForCartLineItems(
    input: ProductAvailabilitiesForCartLineItems,
    ctx: IActionContext
): Promise<IProductInventoryInformation[]> {
    // If no input is provided fail out
    if (!input) {
        throw new Error('[getAvailabilitiesForCartLineItems]No valid Input was provided, failing');
    }

    const shippingItems: CartLine[] = [];
    const bopisItems = [];
    let productAvailabilities: IProductInventoryInformation[] = [];

    const cartState = await getCartState(ctx);
    const cart = cartState.cart;
    const channelConfiguration = await getOrgUnitConfigurationAsync({ callerContext: ctx });
    const products = await getActiveCartProductsAction(new ActiveCartProductsInput(), ctx);
    const PickupDeliveryModeCode = channelConfiguration.PickupDeliveryModeCode;

    if (!cart || !channelConfiguration || !products || products.length === 0) {
        ctx.trace('[getAvailabilitiesForCartLineItems] Not able to get cart OR channelConfiguration or no products in cart');
        return <IProductInventoryInformation[]>[];
    }

    if (cart && cart.Id && cart.CartLines && cart.CartLines.length > 0 && channelConfiguration) {
        for (const cartLine of cart.CartLines) {
            if (cartLine.DeliveryMode && cartLine.DeliveryMode !== '' && cartLine.DeliveryMode === PickupDeliveryModeCode) {
                bopisItems.push(cartLine);
            } else {
                shippingItems.push(cartLine);
            }
        }
    }

    if (shippingItems && shippingItems.length > 0) {
        let productIds = shippingItems.map(x => x.ProductId!);
        productIds = ArrayExtensions.unique(productIds);

        const shippingProductAvailabilities = await getEstimatedAvailabilityAsync(
            { callerContext: ctx, bypassCache: 'get' },
            { ProductIds: productIds, DefaultWarehouseOnly: true }
        );

        if (shippingProductAvailabilities &&
            shippingProductAvailabilities.ProductWarehouseInventoryAvailabilities &&
            shippingProductAvailabilities.ProductWarehouseInventoryAvailabilities.length > 0) {

            productAvailabilities = mapProductInventoryInformation(
                ctx,
                shippingProductAvailabilities?.ProductWarehouseInventoryAvailabilities
            );
        }
    }

    if (bopisItems && bopisItems.length > 0) {
        for (const bopisItem of bopisItems) {
            const productWarehouse: ProductWarehouse = {
                ProductId: bopisItem.ProductId,
                InventLocationId: bopisItem.WarehouseId
            };

            if (ctx.requestContext.channel && ctx.requestContext.channel.InventLocationDataAreaId) {
                productWarehouse.DataAreaId = ctx.requestContext.channel.InventLocationDataAreaId;
            }
            const getProductWarehouseAvail = await getEstimatedProductWarehouseAvailabilityAsync(
                { callerContext: ctx, bypassCache: 'get', queryResultSettings: {} },
                [productWarehouse]
            );

            if (getProductWarehouseAvail &&
                getProductWarehouseAvail.ProductWarehouseInventoryAvailabilities &&
                getProductWarehouseAvail.ProductWarehouseInventoryAvailabilities.length > 0) {
                const productWarehouseMapping = mapProductInventoryInformation(
                    ctx,
                    getProductWarehouseAvail?.ProductWarehouseInventoryAvailabilities
                );
                if (productWarehouseMapping && productWarehouseMapping.length) {
                    for (const item of productWarehouseMapping) {
                        productAvailabilities.push(item);
                    }
                }
            }
        }
    }

    if (productAvailabilities && productAvailabilities.length > 0) {
        return productAvailabilities;
    }

    ctx.trace('[getAvailabilitiesForCartLineItems] unable to get availabilities for product');
    return <IProductInventoryInformation[]>[];
}

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-availabilities-cartlines',
    action: <IAction<IProductInventoryInformation[]>>getAvailabilitiesForCartLineItems,
    input: createInput
});
