import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { OrgUnitAvailability, OrgUnitLocation, SearchArea, StoreHours } from '@msdyn365-commerce/retail-proxy';
import { getOrgUnitLocationsByAreaAsync, getStoreHoursAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/OrgUnitsDataActions.g';
import { IFullOrgUnitAvailability } from './utilities/full-org-unit-availability';

/**
 * Get selected variant action input class
 */
export class GetOrgUnitLocationsByAreaInput implements IActionInput {
    public Latitude?: number;
    public Longitude?: number;
    public Radius?: number;
    public DistanceUnitValue?: number;
    public IgnoreLocation?: boolean;

    constructor(_Latitude?: number, _Longitude?: number, _Radius?: number, _DistanceUnitValue?: number, _IgnoreLocation?: boolean) {
        this.Latitude = _Latitude;
        this.Longitude = _Longitude;
        this.Radius = _Radius;
        this.DistanceUnitValue = _DistanceUnitValue;
        this.IgnoreLocation = _IgnoreLocation;
    }

    public getCacheKey = () => `GetOrgUnitLocationsByAreaInput`;
    public getCacheObjectType = () => 'GetOrgUnitLocationsByAreaInput';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * CreateInput method for the getSelectedVariant data action
 * @param inputData The input data passed to the createInput method
 */
export const createGetOrgUnitLocationsByAreaInput = (inputData: ICreateActionContext<IGeneric<IAny>>): GetOrgUnitLocationsByAreaInput => {
    return new GetOrgUnitLocationsByAreaInput();
};

/**
 * Action method for the getSelectedVariant data action
 * @param input The action input class
 * @param ctx The action context
 */
export async function getOrgUnitLocationsByArea(
    input: GetOrgUnitLocationsByAreaInput,
    ctx: IActionContext
): Promise<IFullOrgUnitAvailability[] | undefined> {

    if ((input.Radius === undefined || !input.Latitude || !input.Longitude) && !input.IgnoreLocation) {
        // No valid location we want to return empty array so module can show no locations message
        return [];
    }

    const searchArea: SearchArea = {
        Latitude: input.Latitude,
        Longitude: input.Longitude,
        Radius: input.Radius,
        DistanceUnitValue: input.DistanceUnitValue || 0 // 0 is miles
    };

    return getOrgUnitLocationsByAreaAsync({ callerContext: ctx }, searchArea)
        .then((stores: OrgUnitLocation[]) => {
            const locationPromiseList = stores.map(store => _getLocationWithHours(store, ctx));
            return Promise.all(locationPromiseList);
        })
        .catch((error: Error) => {
            ctx.trace('[GetOrgUnitLocationsByArea] error getting Locations');
            ctx.trace(error.message);
            ctx.telemetry.error(error.message);
            ctx.telemetry.debug(`[GetOrgUnitLocationsByArea] error getting Locations`);
            return [];
        });
}

/**
 * Action method that obtains the store information
 * @param orgUnitLocation The org unit location
 * @param storeMap a map that contains store information group by the inventory location id
 * @param ctx The action context
 */
async function _getLocationWithHours(
    orgUnitLocation: OrgUnitLocation,
    ctx: IActionContext): Promise<IFullOrgUnitAvailability> {
    if (!orgUnitLocation || !orgUnitLocation.OrgUnitNumber) {
        return { OrgUnitAvailability: undefined };
    }

    return getStoreHoursAsync({ callerContext: ctx }, orgUnitLocation.OrgUnitNumber)
        .then((hours: StoreHours) => {

            const availability: OrgUnitAvailability = {
                OrgUnitLocation: orgUnitLocation
            };

            if (hours && !(hours instanceof Error)) {
                return { OrgUnitAvailability: availability, StoreHours: hours };
            }

            return { OrgUnitAvailability: availability };
        })
        .catch((error: Error) => {
            ctx.trace('[GetFullAvailableInventoryNearby] error getting availability with hours');
            ctx.trace(error.message);
            ctx.telemetry.exception(error);
            ctx.telemetry.debug(`[GetFullAvailableInventoryNearby] error getting availability with hours`);
            return { OrgUnitAvailability: {} };
        });
}

/**
 * The complete getOrgUnitLocationsByArea data action
 */
export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-store-location-information',
    action: <IAction<IFullOrgUnitAvailability[] | undefined>>getOrgUnitLocationsByArea,
    input: createGetOrgUnitLocationsByAreaInput
});